<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap" @contextmenu.prevent @drag.prevent>
        <SendSMSPopup v-model="pop.selected" v-if="pop.isShowSMS" @close="pop.isShowSMS=false"></SendSMSPopup>

        <CarrotTitle title="명함 관리">
            <div class="title-tipbox float-right">
                <span class="float-right title-tip">보안 - 이 페이지는 보안페이지입니다. 복사 및 외부유출을 금합니다.</span>
            </div>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div v-if="bbs.isMounted==true">
                    <table class="table-col">
                        <colgroup>
                            <col width="115">
                            <col width="115">
                            <col width="115">
                            <col width="115">
                            <col width="*">
                            <col width="115">
                            <col width="115">
                            <col width="115">
                            <col width="115">
                            <col width="115">
                        </colgroup>
                        <thead>
                            <tr>
                                <th v-for="(irow, i) in bbs.analysis.list" :key="i">{{ irow.name }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-for="(irow, i) in bbs.analysis.list" :key="i">{{ irow.cnt }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table-row table-serach mt-50">
                        <tbody>
                            <tr>
                                <th width="180">기간</th>
                                <td colspan="3">
                                  <span class="float-left" >
                                    <carrot-date-picker v-model="bbs.sdate" class="dp-inblock w-110px mr-5 "></carrot-date-picker>
                                    ~
                                    <carrot-date-picker v-model="bbs.edate" class="dp-inblock w-110px ml-5"></carrot-date-picker>
                                  </span>
                                  <button @click="bbs.dateInit" class="btn-default ml-10 float-left">기간 재선택</button>
                                </td>
                            </tr>
                            <tr>
                              <th width="180">본부/팀/매니저</th>
                              <td colspan="3">

                                <span class="float-left">
                                  <input type="radio" v-model="bbs.is_updater" value="Y" > <span class="ml-5 mr-20"> 최근 수정자</span>
                                  <input type="radio" v-model="bbs.is_updater" value="N" > <span class="ml-5 mr-20"> 등록자</span>
                                </span>

                                <carrot-dept v-model="bbs.office" class="float-left w-120px"></carrot-dept>
                                <carrot-team :idx_office="bbs.office" v-model="bbs.team" class="float-left w-120px ml-5"></carrot-team>
                                <carrot-staff :idx_office="bbs.office" :idx_team="bbs.team" v-model="bbs.staff" class="float-left w-120px ml-5"></carrot-staff>
                              </td>
                            </tr>

                            <tr>
                                <th width="180">이름</th>
                                <td>
                                    <input type="text" v-model.trim="bbs.bname" class="w-90per" maxlength="50">
                                </td>
                                <th width="180">Mobile</th>
                                <td>
                                  <input type="text" v-model.trim="bbs.mobile" @input="bbs.chkMobile" class="w-90per" maxlength="20">
                                </td>
                            </tr>
                            <tr>
                              <th width="180">고객사</th>
                              <td>
                                <carrot-company v-model="bbs.company" class="w-90per"></carrot-company>
                              </td>
                              <th width="180">고객사 구분[n]</th>
                              <td>
                                <div>
                                  <label v-for="(irow, i) in bbs.mtype_list" :key="i"><input type="checkbox" v-model="bbs.mtype" :value="irow.code"><span class="ml-5 mr-20"> {{ irow.name }}</span></label>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th width="180">VIP여부[n]</th>
                              <td>
                                <select v-model.trim="bbs.is_vip" class="w-90per">
                                  <option value="">선택</option>
                                  <option value="Y">Y</option>
                                  <option value="N">N</option>
                                </select>
                              </td>
                              <th width="180">메인담당자[n]</th>
                              <td>
                                <select v-model.trim="bbs.is_main" class="w-90per">
                                  <option value="">선택</option>
                                  <option value="Y">Y</option>
                                  <option value="N">N</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                                <th width="180">DM 발송 여부[n]</th>
                                <td>
                                  <select v-model.trim="bbs.is_dm" class="w-90per">
                                    <option value="">선택</option>
                                    <option value="Y">Y</option>
                                    <option value="N">N</option>
                                  </select>
                                </td>
                                <th width="180">사보 발송 여부</th>
                                <td>
                                    <select v-model.trim="bbs.newsletter" class="w-90per">
                                        <option value="">선택</option>
                                        <option value="Y">Y</option>
                                        <option value="N">N</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="bbs.doInit" class="btn-default float-right mt-10">검색</button>
                    <div class="clear"></div>

                    <div class="mt-50 mb-20">
                        <select v-model="bbs.state" @click="bbs.doInit" class="w-200px float-left mr-10">
                            <option value="Y">활성화</option>
                            <option value="N">비활성화</option>
                            <option value="">활성화 유무 전체</option>
                        </select>
                        <button @click="bbs.doStopNewsletter" class="btn-default float-left h-30px">사보발송 정지</button>
                        <router-link :to="{ name:'customerManagement-businessCardAdd' }"><button class="btn-default float-right h-30px ml-10">명함 등록</button></router-link>
                        <button @click="pop.showSMS" class="btn-default float-right h-30px ml-10">SMS 보내기</button>
                        <button v-if="store.state.isManageCompanyAdmin" class="btn-default float-right h-30px" @click="bbs.downExcel()">엑셀 다운로드</button>
                        <div class="clear"></div>
                    </div>
                    <table class="table-col">
                        <colgroup>
                            <col width="40">
                            <col width="60">
                            <col width="100">
                            <col width="*">
                            <col width="120">
                            <col width="80">
                            <col width="120">
                            <col width="100">
                            <col width="120">
                            <col width="80">
                            <col width="80">
                            <col width="140">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <label><input type="checkbox" id="listSelAll" value="listSelAll"></label>
                                </th>
                                <th>No</th>
                                <th>등록일</th>
                                <th>고객사</th>
                                <th>고객사유형</th>
                                <th>단계</th>
                                <th>이름</th>
                                <th>직급</th>
                                <th>Mobile</th>
                                <th>재직 여부</th>
                                <th>사보 발송</th>
                                <th>HQ매니저</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>
                                    <label><input type="checkbox" v-model="bbs.selected" :value="irow.idx"></label>
                                </td>
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.wdate }}</td>
                                <td class="txt-ellipsis"><router-link :to="{ name:'customerManagement-customerDBViewTab1-idx', params:{ idx:irow.idx_company } }"><span class="btn-view">{{ irow.company_kname }}</span> <span v-if="bbs.chkNewBtn(irow.wdate)" class="my-box-list-new">N</span></router-link></td>
                                <td>{{ irow.company_type }}</td>
                                <td>{{ irow.step }}단계</td>
                                <td class="txt-ellipsis"><router-link :to="{ name:'customerManagement-businessCardView-idx', params:{ idx:irow.idx } }"><span class="btn-view">{{ irow.kname }}</span></router-link></td>
                                <td class="txt-ellipsis">{{ irow.position }}</td>
                                <td>{{ irow.mobile }}</td>
                                <td>{{ irow.is_work=='Y' ? '재직중' : '퇴사' }}</td>
                                <td>{{ irow.is_newsletter }}</td>
                                <td>{{ irow.hq_ename }}({{ irow.hq_kname }})</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="11">명함이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex'
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import CarrotStaff from '@/components/common/CarrotStaff.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'
import SendSMSPopup from '@/components/popup/customerManagement/SendSMSPopup.vue'

export default {
    layout:"customerManagement",
    components: {
        CarrotDatePicker,
        CarrotDept,
        CarrotTeam,
        CarrotStaff,
        CarrotCompany,
        SendSMSPopup
    },
    setup() {
        const toast = useToast()
        const store = useStore()

        const pop = reactive({
            isShowSMS : false,
            selected : [],

            showSMS : () => {
                pop.selected = bbs.selected;

                if( pop.selected.length > 0 ){
                    pop.isShowSMS = true;
                } else {
                    Swal.fire({
                        title : 'SMS 보내기',
                        text  : "SMS 보낼 대상을 선택하세요."
                    });
                }
            },

            hideSMS : () => {
                pop.isShowSMS = false;
            }
        });

        const bbs = reactive({
            isMounted : false,

            page : 1,
            rows : 50,

            sdate : "",
            edate : "",
            office : 0,
            team   : 0,
            staff  : 0,
            is_updater:'Y',

            bname   : "",
            company : 0,
            mobile  : "",
            newsletter : "",
            state      : "Y",

            analysis : { list:[], total:0 },

            list : [], total : 0,

            selected : [],
            mtype_list : [],
            mtype     : [],
            is_vip : "",
            is_main : "",
            is_dm : "",

            downExcel : () => {
                if( bbs.isMounted == false ) return;
                bbs.mtype
                let url = "https://api.carrotians.net/excel/bizcard_list" 
                + "?sdate=" + bbs.sdate 
                + "&edate=" + bbs.edate 
                + "&office=" + bbs.office 
                + "&team=" + bbs.team 
                + "&staff=" + bbs.staff 
                + "&bname=" + bbs.bname
                + "&company=" + bbs.company
                + "&mobile=" + bbs.mobile
                + "&newsletter=" + bbs.newsletter
                + "&state=" + bbs.state
                + "&mtype[]=" + bbs.mtype.join('|')
                + "&is_vip=" + bbs.is_vip
                + "&is_main=" + bbs.is_main
                + "&is_dm=" + bbs.is_dm;
                console.log(bbs.mtype.join('|'))
                window.open(url, "_blank").focus();
            },

            doStopNewsletter: () => {
                let params = {
                    selected : bbs.selected
                };

                if( params.selected.length <= 0 ){
                    Swal.fire("대상자를 체크해주세요.");
                    return;
                }

                axios.post("/rest/customermgr/setStopNewsletter", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire("정지하였습니다.");
                        bbs.doSearch();
                        bbs.selected = [];
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            chkMobile : () => {
                bbs.mobile = bbs.mobile.replace(/[^0-9-]/gi, '');
            },
            dateInit : () => {
              bbs.sdate="";
              bbs.edate="";
            },

            doInit : () => {
                bbs.page = 1;
                bbs.doSearch();
            },

            doSearchInfo : () => {
                let params = { };

                axios.get("/rest/customermgr/analysisBizcard", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.analysis = res.data
                        bbs.isMounted = true;
                        bbs.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            getMtypeList : () => {
              let params = { };

              axios.get("/rest/customermgr/getMtypeList", { params : params }).then((res) => {
                if( res.data.err == 0 ){
                  bbs.mtype_list = res.data.list;
                } else {
                  if(res.data.err_msg) toast.error(res.data.err_msg);
                }
              });
            },
            doSearch : () => {
                if( bbs.isMounted == false ) return;

                let params = {
                    page : bbs.page,
                    rows : bbs.rows,

                    sdate : bbs.sdate,
                    edate : bbs.edate,
                    office : bbs.office,
                    team   : bbs.team,
                    staff  : bbs.staff,

                    bname   : bbs.bname,
                    company : bbs.company,
                    mobile  : bbs.mobile,
                    newsletter : bbs.newsletter,
                    state      : bbs.state,
                    mtype  : bbs.mtype,
                    is_vip : bbs.is_vip,
                    is_main : bbs.is_main,
                    is_dm : bbs.is_dm,
                    is_updater : bbs.is_updater,
                };

                axios.get("/rest/customermgr/getBizcardList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            chkNewBtn : (date) => {
                var nowDate = new Date();
                var tmpDate = new Date(date);
                if(nowDate - tmpDate < 86400000 ) return true;
                else return false;
            },
        });

        onMounted(() => {
            // Mounted
            bbs.doSearchInfo();
            bbs.getMtypeList();
        });

        return {pop, bbs, store};
    }
}
</script>

<style lang="scss" scoped>
.con-wrap {
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
</style>